import styled, { keyframes}  from "styled-components";

export const Wrapper = styled.div`
  padding-bottom: 4rem;
  background-image: url(${props => props.background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  
  
`;

export const IntroWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
 

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;



export const H2 = styled.h2`
  color: #ffe402;
  text-shadow: #000 0px 0px 6px;
  /* -webkit-text-stroke: 1px black; */
  font-size: 8em;
  letter-spacing: 1px;
  text-align: center;
  /* margin-top: 100px; */

  margin-bottom: 0px;
  /* text-transform: uppercase ; */


  @media(max-width: 1190px) {
    font-size: 8vw;
  }


  

  @media(max-width: 960px) {
    letter-spacing: -2px;
    font-size: 9vw;

  }
  @media(max-width: 690px) {
    letter-spacing: 0px;

  }
  @media(max-width: 480px) {
    letter-spacing: 1px;
  }
`

export const Subtitle = styled.span`
  color: #ffe402;
  text-shadow: #000 0px 0px 4px;
  /* -webkit-text-stroke: 1px black; */
  font-size: 2.2em;
  text-align: center;
  letter-spacing: 0.5px;
  line-height: 37px;
  font-weight: 700;

  


  @media(max-width: 768px) {
    font-size: 2.5em;
    letter-spacing: 0.3px;
    line-height: 30px;
    margin-left: 30px;
    margin-right: 30px;
  }
  @media(max-width: 500px) {
    font-size: 1.8em;
    letter-spacing: 0.2px;
    margin-left: 10px;
    margin-right: 10px;

  }
  @media(max-width: 400px) {
    font-size: 1.6em;
    letter-spacing: 0.1px;
    margin-left: 12px;
    margin-right: 12px;

  }
`;

export const Content = styled.div`
  position: absolute;
  top: 100px;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`