import React from "react";
import { Layout, Seo } from "components/common";
import { Intro } from "components/Intro"
import { MainContent } from "components/MainContent"
import GridModalImages from "components/GridModalImages";

import { photovoltaikContent } from "pagesData/photovoltaik";
import { IntroNoAnim } from "components/IntroNoAnim";
import { IntroRando } from "components/IntroRandom";

const Lastmanagement = () => (
  <Layout>
    <Seo />
    {/* <IntroNoAnim {...photovoltaikContent} /> */}
    <IntroRando {...photovoltaikContent} />
    <MainContent content={photovoltaikContent.mainContent} />
    <GridModalImages images={photovoltaikContent.modalImages} />
  </Layout>
);

export default Lastmanagement;
