
import eins from "assets/bilder/photovoltaik/AD_9677_rgb.jpg"
import zwei from "assets/bilder/photovoltaik/AD_9695_rgb_1.jpg"
import drei from "assets/bilder/photovoltaik/BAD_0190_rgb.jpg"
import vier from "assets/bilder/photovoltaik/BAD_0194_rgb.jpg"
import fof from "assets/bilder/photovoltaik/BAD_0324_rgb.jpg"
import six from "assets/bilder/photovoltaik/BAD_0423_rgb.jpg"



import bg1 from "assets/bilder/photovoltaik/KV_IMG_7005_rgb.jpg"
import bg2 from "assets/bilder/photovoltaik/KV_AD_9711_rgb.jpg"
import bg3 from "assets/bilder/photovoltaik/KV_BAD_0368_rgb.jpg"


export const photovoltaikContent = {
    h2: "Photovoltaik",
    subtitle: "Damit Ihr Solarstrom ungehindert fliesst. ",
    bgs: [
        bg1,bg2,bg3
      ],
    mainContent: `
    <p>
    Es ist ganz einfach - produzieren Sie Ihren Strom mit der Sonne und versorgen so Ihren eigenen Energiehaushalt.
     Unsere Eigenverbrauchsoptimierung, kurz EVO, analysiert 
    in Echtzeit den Stromverbrauch und steuert die Energie intelligent und effizient durchs Haus.
     Behalten Sie die Energie Zuhause und verhindern Sie, dass überschüssiger Strom ins Netz gespeist werden muss. 
     Gewappnet gegen eine mögliche Stromknappheit
     sparen Sie mit diesem Engagement bares Geld und leisten aktiv Ihren Beitrag zur Energiewende.
     <br/><br/>

     Unser Wissen, dass auf jahrelanger Erfahrung beruht und laufend erweitert wird, geben wir in Form von Transparenten,
      auf das Projekt und den angestrebten Nutzen,
      fokussierte Beratungen weiter. Denn unsere Lösung ist immer spezifisch auf den Kunden zugeschnitten.
      <br/><br/>

      Dieser Service beinhaltet den Erstkontakt, die Bestandesaufnahme, Projektierung, Installation,
       Inbetriebnahme, Übergabe und Wartung einer Anlage. Wir kümmern uns um alles – auch Behördengänge 
       in Zusammenhang mit Bewilligungsverfahren erledigen wir für Sie.
       Mit uns läuft es einfach, reibungslos und fair.
       <br/><br/>

       Für weitere Informationen stehen wir Ihnen jederzeit über unser
        <a href="/kontakt?anliegen=photovoltaik">Kontaktformular</a> zur Verfügung,
        schreiben Sie uns ein <a href="mailto:info@elektro-huwiler.ch">E-Mail</a> oder wir nehmen Ihren Anruf gerne auf
         <a href="tel:0447776544">044 777 65 44</a> entgegen. 

                </p>
    `,
    modalImages: [
       
        drei,
        vier,
        fof,
        six,
        eins,
        zwei,
    ],
}